import i18n from '@/helper/i18n';
import { CURRENCY_DEFAULT } from '@/helper/constants';
import { convertMoneyToDefaultCurrency } from '@/helper/util/common';

const t = i18n.chain;

const getPaxItems = (type, guestsLimit) => {
  if (guestsLimit[type] > 0)
    return [
      {
        currency: '',
        fare_title: `${t.flights.checkout.guestDetailsForm[type]} x${guestsLimit[type]}`,
        price: '',
      },
    ];
  else return [];
};

export const getFareDetails = (
  feeDetails,
  baseCurrency,
  conversionRates,
  guestsLimit,
) => {
  const fareDetails = [];
  const adult = getPaxItems('adult', guestsLimit);
  const child = getPaxItems('child', guestsLimit);
  const infant = getPaxItems('infant', guestsLimit);

  if (feeDetails.baseFare) {
    fareDetails.push({
      fareType: 'baseFare',
      currency: CURRENCY_DEFAULT,
      fareBreakdown: [...adult, ...child, ...infant],
      isDiscount: false,
      title: t.flights.checkout.fareSummary.baseFare,
      totalAmount: convertMoneyToDefaultCurrency(
        feeDetails.baseFare.total,
        baseCurrency,
        conversionRates,
      ),
    });
  }

  if (feeDetails.taxes) {
    fareDetails.push({
      fareType: 'taxes',
      currency: CURRENCY_DEFAULT,
      fareBreakdown: feeDetails.taxes.feeBreakUp
        ? feeDetails.taxes.feeBreakUp.map((b) => ({
            currency: '',
            fare_title:
              b.name ||
              (b.type in t.flights.checkout.fareSummary
                ? t.flights.checkout.fareSummary[b.type]
                : b.type),
            price: '',
          }))
        : [],
      isDiscount: false,
      title: t.flights.checkout.fareSummary.taxes,
      totalAmount: convertMoneyToDefaultCurrency(
        feeDetails.taxes.total,
        baseCurrency,
        conversionRates,
      ),
    });
  }

  if (feeDetails.discount) {
    fareDetails.push({
      fareType: 'discount',
      currency: CURRENCY_DEFAULT,
      fareBreakdown: [],
      isDiscount: true,
      title: t.flights.checkout.fareSummary.fareDiscount,
      totalAmount: convertMoneyToDefaultCurrency(
        feeDetails.discount.total,
        baseCurrency,
        conversionRates,
      ),
    });
  }

  if (feeDetails.serviceCharge) {
    fareDetails.push({
      fareType: 'serviceCharge',
      currency: CURRENCY_DEFAULT,
      fareBreakdown: [],
      isDiscount: false,
      title: t.flights.checkout.fareSummary.serviceCharge,
      totalAmount: convertMoneyToDefaultCurrency(
        feeDetails.serviceCharge.total,
        baseCurrency,
        conversionRates,
      ),
    });
  }

  if (feeDetails.processingFee) {
    fareDetails.push({
      fareType: 'processingFee',
      currency: CURRENCY_DEFAULT,
      fareBreakdown: [],
      isDiscount: false,
      title: t.flights.checkout.fareSummary.processingFee,
      totalAmount: convertMoneyToDefaultCurrency(
        feeDetails.processingFee.total,
        baseCurrency,
        conversionRates,
      ),
    });
  }

  return fareDetails;
};
