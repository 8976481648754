import { action, makeObservable, observable } from 'mobx';
import { ZodiacApi, CommonApi } from '@/api';
import { userStore } from '@/store/base';
import { convertMoneyToDefaultCurrency } from '@/helper/util';
import i18n from '@/helper/i18n';
import { Log } from '@/helper/log';
import Config from '@/config';
import { StoreUtil } from '@/store/common';
import globalLoadingStore from '@/store/common/global-loading';

import GuestsAndContactStore from './guests-and-contact';
import FlightDetailsStore from './flight-details';
import FareSummaryStore from './fare-summary';
import SessionTimerStore from './session-timer';
import CheckoutStore from './checkout';
import AncillaryFastPassStore from './ancillary-fast-pass';
import AncillaryBundleStore from './ancillary-bundle';
import AncillaryBaggageStore from './ancillary-baggage';
import AncillaryMealStore from './ancillary-meal';
import AncillarySeatStore from './ancillary-seat';
import AncillaryInsuranceStore from './ancillary-insurance';
import AncillaryWheelchairStore from './ancillary-wheelchair';
import globalMessageStore from '@/store/common/global-message';

const t = i18n.chain;

export default class AACheckoutStore implements PageStore {
  private CLASS_NAME = 'AACheckoutStore';
  sessionTimerStore = new SessionTimerStore();
  guestsAndContactStore = new GuestsAndContactStore();
  flightDetailsStore = new FlightDetailsStore();
  fareSummaryStore = new FareSummaryStore();
  checkoutStore = new CheckoutStore();
  ancillaryBundleStore = new AncillaryBundleStore();
  ancillaryFastPassStore = new AncillaryFastPassStore();
  ancillaryBaggageStore = new AncillaryBaggageStore();
  ancillaryMealStore = new AncillaryMealStore();
  ancillarySeatStore = new AncillarySeatStore();
  ancillaryInsuranceStore = new AncillaryInsuranceStore();
  ancillaryWheelchairStore = new AncillaryWheelchairStore();
  @observable locale: any = {};

  @observable dataLoaded = false;
  @observable hasError = false;

  tripsInfo: TripsInfo = null;
  originalPageData = null;
  currencyInfo: AACheckout.CurrencyInfo = null;

  constructor() {
    makeObservable(this);
    this.convertCurrency = this.convertCurrency.bind(this);
  }

  @action
  onLoad = async () => {
    document.title = t.flights.checkout.title;

    try {
      await this.parseSearchParams();
      const tokenInfo = await userStore.getFlushedTokenInfo();

      const originalPageData = await ZodiacApi.getPageData(
        this.tripsInfo.tripIds,
        this.tripsInfo.airlineProfile,
        tokenInfo,
      );

      this.locale = originalPageData.locale;
      this.originalPageData = originalPageData;

      const { flightDetails } = originalPageData;

      if (!flightDetails || flightDetails.error) {
        throw new Error('Load data error');
      }

      //set currency info
      this.setCurrencyInfo();
      this.dataLoaded = true;
    } catch (error) {
      this.hasError = true;
      throw error;
    }
  };

  setCurrencyInfo = () => {
    const { conversionRates, currencyCode: baseCurrency } =
      this.originalPageData.currency;
    this.currencyInfo = {
      isLoadedCurrency: true,
      baseCurrency,
      conversionRates,
    };
  };

  convertCurrency = (amount: number): number => {
    return convertMoneyToDefaultCurrency(
      amount,
      this.currencyInfo.baseCurrency,
      this.currencyInfo.conversionRates,
    );
  };

  @action
  onUnload = async () => {};

  @action
  onContinue = async () => {
    if (!this.fareSummaryStore.legalAgreed) return;

    globalLoadingStore.open();

    try {
      await this.guestsAndContactStore.validateForm();
      await this.guestsAndContactStore.applyAdditionalValidation();

      // validate bundle data
      const isBundleValid = this.ancillaryBundleStore.validate();
      if (!isBundleValid) {
        // WMP-2905: global LoadingStore will be closed in meal or seat component
        return;
      }

      const tripsInfo = this.tripsInfo;
      const guestsAndContact = this.guestsAndContactStore.composeCheckoutData();
      const fareSummary = this.fareSummaryStore.composeCheckoutData();
      const flightDetails = this.flightDetailsStore.composeCheckoutData();
      const loggedInUserInfo = await this.composeLoggedInUserInfo();
      const fastPassAddonItems =
        this.ancillaryFastPassStore.generateCheckoutAddonItems();
      const baggageAddonItems =
        this.ancillaryBaggageStore.generateCheckoutAddonItems();
      const seatAddonItems =
        this.ancillarySeatStore.generateCheckoutAddonItems();
      const mealAddonItems =
        this.ancillaryMealStore.generateCheckoutAddonItems();
      const insuranceAddonItems =
        this.ancillaryInsuranceStore.generateCheckoutAddonItems();
      const wheelchairAddonItems =
        this.ancillaryWheelchairStore.generateCheckoutAddonItems();
      const bundleAddonItems =
        this.ancillaryBundleStore.generateCheckoutAddonItems();

      await this.checkoutStore.performCheckout({
        guestsAndContact,
        fareSummary,
        flightDetails,
        loggedInUserInfo,
        tripsInfo,
        selectedAncillaries: [
          ...bundleAddonItems,
          ...fastPassAddonItems,
          ...baggageAddonItems,
          ...mealAddonItems,
          ...insuranceAddonItems,
          ...seatAddonItems,
          ...wheelchairAddonItems,
        ],
      });

      globalLoadingStore.close();
    } catch (error) {
      const { message } = error;

      message && globalMessageStore.show(message);
      globalLoadingStore.close();

      Log.errorData(error, this.CLASS_NAME, 'onContinue', {});
    }
  };

  private parseSearchParams = async () => {
    try {
      const data = await StoreUtil.parseSearchParams();
      this.tripsInfo = data?.tripsInfo;
    } catch (error) {
      this.hasError = true;
    }
  };

  private composeLoggedInUserInfo = async () => {
    const loggedIn = userStore.isValidated && userStore.isLogin;

    let loggedInUserInfo = null;

    if (loggedIn) {
      const tokenInfo = await userStore.getFlushedTokenInfo();
      const apiKey = await CommonApi.SSOAuth.getApiKey();
      const loggedUsr = userStore.user;

      const findCountries = this.guestsAndContactStore.countries.filter(
        (m) => m.dialingCode.replace('+', '') === loggedUsr.dialingCode,
      );

      let countryCode = null;
      if (findCountries.length === 1) {
        countryCode = findCountries[0].value;
      }

      loggedInUserInfo = {
        firstName: loggedUsr.firstName,
        middleName: null,
        lastName: loggedUsr.lastName,
        title: loggedUsr.title,
        gender: loggedUsr.gender,
        dob: loggedUsr.dateOfBirth || null,
        emailId: loggedUsr.username,
        countryCode,
        dialingCode: loggedUsr.dialingCode,
        nationality: loggedUsr.nationality || null,
        residentCountry: null,
        customerNumber: loggedUsr.navitaireCustomerNumber || null,
        bigMemberId: loggedUsr.loyaltyId,
        paxType: loggedUsr.paxType,
        ssoDetails: {
          userId: loggedUsr.id,
          origin: Config.SSO.ORIGIN,
          clientId: Config.SSO.CLIENT,
          apiKey: apiKey,
          accessToken: tokenInfo.accessToken,
          refreshToken: tokenInfo.refreshToken,
          phoneNumberVerified: loggedUsr.phoneNumberVerified,
          notificationPreference: loggedUsr.notificationPreference,
          ssoStatus: loggedUsr.status,
        },
        postalCode: null,
        state: null,
        city: null,
      };
    }

    return loggedInUserInfo;
  };
}
