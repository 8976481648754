import { AACheckoutConst } from '@/helper/constants';

class BundleInfluence {
  private originalBaggageResponse: AACheckout.BaggageResponse | null = null;

  constructor() {}

  /**
   * update load data
   */
  updateLoadData(
    baggageResponse: AACheckout.BaggageResponse,
    bundleInfluenceData: AACheckout.BundleInfluenceData,
  ): any {
    const isBundleSelected = this.checkIfBundleSelected(bundleInfluenceData);
    const includedIcon = isBundleSelected ? 'StarInvertedIcon' : '';

    // save original baggageResponse
    if (!this.originalBaggageResponse) {
      this.originalBaggageResponse = baggageResponse;
    }

    // clone baggageResponse
    const clonedBaggageResponse = JSON.parse(JSON.stringify(baggageResponse));

    // update baggageResponse with new bundle influence
    if (bundleInfluenceData?.departFlight?.includedSsrs?.length > 0) {
      this.updateBaggageResponse(
        clonedBaggageResponse.ancillary.baggage.data[0].ancillarylist,
        bundleInfluenceData.departFlight.includedSsrs[0],
      );
    } else {
      // if there is no depart, restore the original data
      clonedBaggageResponse.ancillary.baggage.data[0].ancillarylist =
        this.originalBaggageResponse.ancillary.baggage.data[0].ancillarylist;
    }

    if (bundleInfluenceData?.returnFlight?.includedSsrs?.length > 0) {
      this.updateBaggageResponse(
        clonedBaggageResponse.ancillary.baggage.data[1]?.ancillarylist,
        bundleInfluenceData.returnFlight.includedSsrs[0],
      );
    } else {
      // if there is no return, restore the original data
      if (clonedBaggageResponse.ancillary.baggage.data[1]) {
        clonedBaggageResponse.ancillary.baggage.data[1].ancillarylist =
          JSON.parse(
            JSON.stringify(
              this.originalBaggageResponse.ancillary.baggage.data[1]
                .ancillarylist,
            ),
          );
      }
    }

    return {
      baggageResponse: clonedBaggageResponse,
      isBundleSelected,
      includedIcon,
    };
  }

  excludeSSRBundleBaggage(plainAddonItems: AACheckout.PlainAddonItem[]) {
    return plainAddonItems.filter((p) => !p.isBundled);
  }

  divideBundleUpgradeBaggage(
    plainAddonItems: AACheckout.PlainAddonItem[],
    bundleInfluenceData: AACheckout.BundleInfluenceData,
  ) {
    return plainAddonItems.map((item) => {
      const isBundled =
        this.checkIfUpgradeBundled(
          item,
          bundleInfluenceData,
          AACheckoutConst.FlightKeyEnum.DEPART_FLIGHT,
        ) ||
        this.checkIfUpgradeBundled(
          item,
          bundleInfluenceData,
          AACheckoutConst.FlightKeyEnum.RETURN_FLIGHT,
        );

      item.isBundled = isBundled;
      return item;
    });
  }

  private checkIfUpgradeBundled(
    item: AACheckout.PlainAddonItem,
    bundleInfluenceData: AACheckout.BundleInfluenceData,
    flightType: AACheckoutConst.FlightKeyEnum,
  ): boolean {
    if (!(item && bundleInfluenceData && flightType)) {
      return false;
    }

    const flight = bundleInfluenceData[flightType];
    if (!flight) return false;

    const isMatchingFlight =
      flight.designator.departureStation === item.departureStation &&
      flight.designator.arrivalStation === item.arrivalStation;

    if (!isMatchingFlight) return null;

    const bundleIncludedSsr = flight.includedSsrs[0];

    return !!this.checkIsAvailableUpgrade(bundleIncludedSsr, item.id);
  }

  private checkIfBundleSelected(
    bundleInfluenceData: AACheckout.BundleInfluenceData,
  ): boolean {
    return (
      bundleInfluenceData &&
      ((bundleInfluenceData.departFlight &&
        bundleInfluenceData.departFlight.includedSsrs.length > 0) ||
        (bundleInfluenceData.returnFlight &&
          bundleInfluenceData.returnFlight.includedSsrs.length > 0))
    );
  }

  private updateBaggageResponse(
    list: AACheckout.BaggageAncillaryList,
    bundleItem: AACheckout.BundleIncludedSsr,
  ) {
    if (!list) return;
    Object.entries(list).forEach(([option, bags]) => {
      if (!bags) return;
      const newList = bags.reduce((acc: any[], bag: any) => {
        return this.updateBagIfEligible(bag, bundleItem, acc);
      }, []);
      if (newList.length) list[option] = newList;
    });
  }

  private checkIsAvailableUpgrade(bundleIncludedSsr: any, bagId: string) {
    const bundleBagOption =
      bundleIncludedSsr?.journeyDetails?.[0]?.availableUpgrade;
    const optionPresent = bundleBagOption?.find(
      (bundleBag: any) => bundleBag.id === bagId,
    );
    return optionPresent;
  }

  private updateBagIfEligible(
    bag: any,
    bundleIncludedSsr: AACheckout.BundleIncludedSsr,
    newList: any[],
  ): any[] {
    const optionPresent = this.checkIsAvailableUpgrade(
      bundleIncludedSsr,
      bag.id,
    );

    if (!optionPresent && bundleIncludedSsr.ssrCode === bag.id) {
      // check for the free baggage included in the bundle (like PBAB in VP)
      bag.rules = { ...bag.rules, withBundle: true, isPreselected: false };
      bag.price.amount = 0;
      newList.push(bag);
    } else if (optionPresent) {
      // if baggage present, update price and properties
      bag.price = optionPresent.price;
      bag.properties = optionPresent.properties;
      bag.rules = { ...bag.rules, isPreselected: false };
      newList.push(bag);
    }
    return newList;
  }
}

export default BundleInfluence;
