import { action, makeObservable, observable } from 'mobx';
import { ZodiacApi } from '@/api';
import { userStore } from '@/store/base';
import { AACheckoutConst } from '@/helper/constants';
import { Log } from '@/helper/log';
import { BundleParser, PlainAddonItemsGenerator } from './parse';

const METHOD_NAMES = {
  LOAD_DATA: 'loadData',
} as const;

export default class AncillaryBundleStore {
  private CLASS_NAME = 'AncillaryBundleStore';
  @observable bundleData: AACheckout.BundleItem[] = [];
  @observable startPrice: number | null = null;
  @observable totalAmount = 0;
  @observable plainAddonItems: AACheckout.PlainAddonItem[] = [];
  @observable plainAddonItemsForCheckout: AACheckout.PlainAddonItem[] = [];
  @observable selectedBundleData: any;
  @observable comparisonData: any = null;

  @observable bundleInfluenceData: {
    [key in AACheckoutConst.BundleAncillaryTypeEnum]?: AACheckout.BundleInfluenceData;
  } = {};
  @observable plainAddonItemMap: {
    [key in AACheckoutConst.BundleAncillaryTypeEnum]?: AACheckout.PlainAddonItem[];
  };
  @observable isBundleValidMap: {
    [key in AACheckoutConst.BundleAncillaryTypeEnum]?: boolean;
  } = {
    meals: true,
    seat: true,
  };
  @observable dataLoading = false;
  @observable isDataLoaded = false;

  bundleParser: BundleParser;
  plainAddonItemsGenerator: PlainAddonItemsGenerator;

  conversionRates: AACheckout.ConversionRates = null;
  paxTitles: AACheckout.PaxTitle[] = [];
  tripsInfo: TripsInfo = null;

  constructor() {
    makeObservable(this);
  }

  @action
  loadData = async () => {
    try {
      if (
        !(
          (this.tripsInfo &&
            this.conversionRates &&
            this.conversionRates.length > 0) ||
          (this.paxTitles && this.paxTitles.length > 0)
        )
      ) {
        return;
      }

      if (this.isDataLoaded || this.dataLoading) return;

      this.dataLoading = true;

      const tokenInfo = await userStore.getFlushedTokenInfo();
      const originalPageData = await ZodiacApi.getPageData(
        this.tripsInfo.tripIds,
        this.tripsInfo.airlineProfile,
        tokenInfo,
        ['bundle'],
        ['ancillary'],
      );

      if (this.isEmptyAncillary(originalPageData)) {
        return;
      }

      this.bundleParser = new BundleParser(
        originalPageData,
        this.conversionRates,
        this.paxTitles.length,
      );
      const { startPrice, bundleData, comparisonData } =
        this.bundleParser.parse();

      this.startPrice = startPrice;
      this.bundleData = bundleData;
      this.comparisonData = comparisonData;
      this.dataLoading = false;
      this.isDataLoaded = true;
    } catch (e) {
      Log.errorData(e, this.CLASS_NAME, METHOD_NAMES.LOAD_DATA);
    }
  };

  isEmptyAncillary(res) {
    return !res.ancillary?.bundle;
  }

  @action
  onSelectedBundle = async (selectedBundleData) => {
    this.selectedBundleData = selectedBundleData;
    this.generatePlainAddonItems();

    //distribute ancillaries
    this.bundleInfluenceData = this.distributeAncillaries(selectedBundleData);
  };

  @action
  clearMealsValidationStatus = () => {
    this.isBundleValidMap.meals = true;
  };

  @action
  clearSeatValidationStatus = () => {
    this.isBundleValidMap.seat = true;
  };

  @action
  distributeAncillaries = (selectedBundleData) => {
    const ancillaries: {
      [key in AACheckoutConst.BundleAncillaryTypeEnum]?: AACheckout.BundleInfluenceData;
    } = {};
    if (!this.bundleParser) {
      return;
    }

    if (selectedBundleData.departFlight) {
      this.bundleParser.processIncludedSsrs(
        selectedBundleData.departFlight,
        AACheckoutConst.FlightKeyEnum.DEPART_FLIGHT,
        ancillaries,
      );
    }

    if (selectedBundleData.returnFlight) {
      this.bundleParser.processIncludedSsrs(
        selectedBundleData.returnFlight,
        AACheckoutConst.FlightKeyEnum.RETURN_FLIGHT,
        ancillaries,
      );
    }

    return ancillaries;
  };

  @action
  updatePlainAddonItemMap(plainAddonItemMap: {
    [key in AACheckoutConst.BundleAncillaryTypeEnum]?: AACheckout.PlainAddonItem[];
  }) {
    this.plainAddonItemMap = plainAddonItemMap;
    this.generatePlainAddonItems();
  }

  generatePlainAddonItems = () => {
    this.plainAddonItemsGenerator = new PlainAddonItemsGenerator(
      this.paxTitles,
      this.plainAddonItemMap,
      this.selectedBundleData,
    );
    const { plainAddonItemsForCheckout, plainAddonItems } =
      this.plainAddonItemsGenerator.generate();

    this.plainAddonItemsForCheckout = plainAddonItemsForCheckout;
    this.plainAddonItems = plainAddonItems;
    this.totalAmount = this.plainAddonItems
      .map((i) => i.price)
      .reduce((a, b) => a + b, 0);
  };

  generateCheckoutAddonItems = () => {
    return this.plainAddonItemsForCheckout.map((addonItem) => {
      return {
        id: addonItem.id,
        category: addonItem.category,
        designator: {
          departureStation: addonItem.departureStation,
          arrivalStation: addonItem.arrivalStation,
          departureTime: null,
          arrivalTime: null,
          departureTimeUtc: null,
          arrivalTimeUtc: null,
          arrivalTerminal: null,
          departureTerminal: null,
        },
        properties: addonItem.properties,
        passengerReferenceId: addonItem.passengerReferenceId,
      };
    });
  };

  @action
  validate() {
    if (!this.selectedBundleData) {
      return true;
    }

    if (this.plainAddonItemsGenerator) {
      const validationResult = this.plainAddonItemsGenerator.validationResult;

      // find first key equal false
      const firstFalseKey = Object.keys(validationResult).find(
        (key) => !validationResult[key],
      );

      if (firstFalseKey) {
        // set other keys equal true
        this.isBundleValidMap = Object.keys(validationResult).reduce(
          (acc, key) => {
            acc[key] = key === firstFalseKey ? false : true;
            return acc;
          },
          {},
        );
      } else {
        // if have not false key
        this.isBundleValidMap = { ...validationResult };
      }

      return !firstFalseKey;
    }

    return true;
  }
}
