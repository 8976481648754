import { convertMoneyToDefaultCurrency } from '@/helper/util/common';
import { CURRENCY_DEFAULT, AACheckoutConst } from '@/helper/constants';

const BUNDLE_COLORS = {
  VP: 'yellow',
  PF: 'pink',
  FB: 'purple',
};

const BUNDLE_KEY_MAPPING = {
  VP: 'valuePack',
  PF: 'premiumFlex',
  FB: 'premiumFlatbed',
};

const ICON_TYPE_MAPPING = {
  luggage: 'LuggageIcon',
  meals: 'RestaurantIcon',
  seat: 'AirLineSeatReclineExtraIcon',
  flatbed: 'AirlineSeatIndividualSuiteIcon',
  calendar: 'EditCalendarIcon',
  express: 'DoubleArrowIcon',
  insurance: 'HealthSafetyIcon',
  lounge: 'EventSeatIcon',
};

type SsrType =
  | AACheckoutConst.BundleAncillaryTypeEnum.MEALS
  | AACheckoutConst.BundleAncillaryTypeEnum.SEAT
  | AACheckoutConst.BundleAncillaryTypeEnum.BAGGAGE;

const getIconByText = (text: string): string => {
  if (text.includes('行李')) return ICON_TYPE_MAPPING.luggage;
  if (text.includes('餐')) return ICON_TYPE_MAPPING.meals;
  if (text.includes('座位')) return ICON_TYPE_MAPPING.seat;
  if (text.includes('时间') || text.includes('日期'))
    return ICON_TYPE_MAPPING.calendar;
  if (text.includes('快速')) return ICON_TYPE_MAPPING.express;
  if (text.includes('保险')) return ICON_TYPE_MAPPING.insurance;
  if (text.includes('贵宾室') || text.includes('休息室'))
    return ICON_TYPE_MAPPING.lounge;
  return ICON_TYPE_MAPPING.seat;
};

export class BundleParser {
  private pageData;
  private conversionRates;
  private guests: number;

  constructor(pageData, conversionRates, guests: number) {
    this.pageData = pageData;
    this.conversionRates = conversionRates;
    this.guests = guests;
  }

  public parse() {
    const { ancillary: originalAncillary = {} } = this.pageData;

    const { bundle = {} } = originalAncillary;
    const {
      isPaxDependent,
      measure,
      startPrice,
      metaData,
      data: journeys,
    } = bundle;
    const { bundleCard: bundleMetaItems, compareBundle } = metaData;
    const bundleData: AACheckout.BundleItem[] = [];

    const { ancillarylist, designator: departDesignator } = journeys[0];
    const returnJourney = journeys[1];

    for (let index = 0; index < ancillarylist.length; index++) {
      const ancillary = ancillarylist[index];

      const bundleMeta = bundleMetaItems.find(
        (f) => f.bundleCode === ancillary.bundleCode,
      );

      const departFlight: AACheckout.BundleFlight = {
        bundleCode: ancillary.bundleCode,
        bundleId: ancillary.bundleId,
        bundleName: ancillary.bundleName,
        designator: {
          arrivalStation: departDesignator.arrivalStation,
          departureStation: departDesignator.departureStation,
        },
        guest: this.guests,
        isAvailable: true,
        includedSsrs: ancillary.includedSsrs,
        price: {
          currency: CURRENCY_DEFAULT,
          price: convertMoneyToDefaultCurrency(
            ancillary.totalPrice,
            ancillary.currency,
            this.conversionRates,
          ),
        },
        type: 'depart',
      };

      let returnFlight: AACheckout.BundleFlight = null;
      if (returnJourney) {
        const {
          ancillarylist: returnAncillarylist,
          designator: returnDesignator,
        } = returnJourney;
        const returnAncillary = returnAncillarylist[index];

        returnFlight = {
          bundleCode: returnAncillary.bundleCode,
          bundleId: returnAncillary.bundleId,
          bundleName: returnAncillary.bundleName,
          designator: {
            arrivalStation: returnDesignator.arrivalStation,
            departureStation: returnDesignator.departureStation,
          },
          guest: this.guests,
          isAvailable: true,
          includedSsrs: returnAncillary.includedSsrs,
          price: {
            currency: CURRENCY_DEFAULT,
            price: convertMoneyToDefaultCurrency(
              returnAncillary.totalPrice,
              returnAncillary.currency,
              this.conversionRates,
            ),
          },
          type: 'return',
        };
      }

      bundleData.push({
        color: BUNDLE_COLORS[ancillary.bundleId] || '',
        content: bundleMeta.body.map((item) => ({
          icon: item.icon || getIconByText(item.text),
          iconColor: BUNDLE_COLORS[ancillary.bundleId] || '',
          text: item.text,
        })),
        departFlight,
        icon: 'CheckIcon',
        isMobile: true,
        returnFlight,
        subTitle: bundleMeta.header.subtitle,
        title: bundleMeta.header.title,
      });
    }

    const result = {
      startPrice,
      isPaxDependent,
      measure,
      bundleData,
      comparisonData: this.parseComparisonData(compareBundle),
    };

    return result;
  }

  private parseComparisonData(compareBundle) {
    const comparisonData = {
      columns: [
        {
          columnKey: 'inclusion',
          columnName: '套餐',
          columnSubheader: '',
        },
      ],
      data: [],
    };

    // generate columns
    compareBundle.forEach((bundle) => {
      const columnKey =
        BUNDLE_KEY_MAPPING[bundle.bundleId] || bundle.bundleId.toLowerCase();
      comparisonData.columns.push({
        columnKey,
        columnName: bundle.header.title,
        columnSubheader: '',
      });
    });

    compareBundle.forEach((bundle) => {
      bundle.body.forEach((item) => {
        const inclusionKey = item.inclusion;
        let comparisonItem = comparisonData.data.find(
          (d) => d.inclusion === inclusionKey,
        );

        if (!comparisonItem) {
          comparisonItem = {
            comparisonType: item.type === 'icon' ? 'icon' : 'text',
            inclusion: inclusionKey,
            inclusionIcon: item.inclusionIcon || getIconByText(item.inclusion),
            inclusionIconColor: 'charcoalGrey',
            inclusionIconPosition: 'Prefix',
          };
          comparisonData.data.push(comparisonItem);
        }

        const columnKey =
          BUNDLE_KEY_MAPPING[bundle.bundleId] || bundle.bundleId.toLowerCase();
        comparisonItem[columnKey] = item.text;
      });
    });

    return comparisonData;
  }

  public processIncludedSsrs(
    flight: AACheckout.BundleFlight,
    flightKey: AACheckoutConst.FlightKeyEnum,
    ancillaries: {
      [key in AACheckoutConst.BundleAncillaryTypeEnum]?: AACheckout.BundleInfluenceData;
    },
  ) {
    flight?.includedSsrs?.forEach((ssr) => {
      if (!ancillaries[ssr.ancillaryType]) {
        ancillaries[ssr.ancillaryType] = {
          departFlight: null,
          returnFlight: null,
        };
      }

      const filteredFlight = {
        ...flight,
        includedSsrs: flight.includedSsrs.filter(
          (includedSsr) => includedSsr.ancillaryType === ssr.ancillaryType,
        ),
      };

      ancillaries[ssr.ancillaryType][flightKey] = filteredFlight;
    });
  }
}

type PlainAddonItemMap = {
  [key in AACheckoutConst.BundleAncillaryTypeEnum]?: AACheckout.PlainAddonItem[];
};

export class PlainAddonItemsGenerator {
  paxTitles: AACheckout.PaxTitle[];
  plainAddonItemMap: PlainAddonItemMap;
  selectedBundleData: any;

  validationResult = {
    meals: true,
    seat: true,
  };

  constructor(
    paxTitles: AACheckout.PaxTitle[],
    plainAddonItemMap: PlainAddonItemMap,
    selectedBundleData: any,
  ) {
    this.paxTitles = paxTitles;
    this.plainAddonItemMap = plainAddonItemMap;
    this.selectedBundleData = selectedBundleData;
  }

  getSelectedAddon(
    type: SsrType,
    departureStation: string,
    arrivalStation: string,
    passengerReferenceId: string,
  ) {
    return this.plainAddonItemMap[type]?.find(
      (m) =>
        m.departureStation === departureStation &&
        m.arrivalStation === arrivalStation &&
        m.passengerReferenceId === passengerReferenceId,
    );
  }

  generateSsrsByType(
    type: SsrType,
    ssrs: AACheckout.BundleIncludedSsr[],
    paxTitle: AACheckout.PaxTitle,
  ) {
    if (!ssrs || ssrs.length === 0) {
      return [];
    }
    const firstSsr = ssrs.shift();

    firstSsr.journeyDetails = firstSsr.journeyDetails.map((j) => {
      const selectedAddon = this.getSelectedAddon(
        type,
        j.designator.origin,
        j.designator.destination,
        paxTitle.paxId,
      );

      if (!selectedAddon && firstSsr.mandatory) {
        this.validationResult[type] = false;
      }

      return {
        ...j,
        selectedSsrs: this.createSelectedSsrs(type, selectedAddon),
      };
    });

    return [firstSsr, ...ssrs];
  }

  createSelectedSsrs(type: SsrType, selectedAddon: any) {
    if (!selectedAddon) return [];

    switch (type) {
      case AACheckoutConst.BundleAncillaryTypeEnum.SEAT:
        return [
          {
            code: selectedAddon.id.toLowerCase(),
            name: selectedAddon.properties?.seatNumber,
          },
        ];

      case AACheckoutConst.BundleAncillaryTypeEnum.BAGGAGE: {
        const weight = selectedAddon.properties?.baggageDimension?.[0]?.weight;

        const name = weight ? `${weight.value} ${weight.unit}` : '';

        return [
          {
            code: selectedAddon.id,
            name,
          },
        ];
      }

      case AACheckoutConst.BundleAncillaryTypeEnum.MEALS:
        return [
          {
            code: selectedAddon.id,
            name: selectedAddon.name,
          },
        ];
    }
  }

  generateSsrs(
    ssrs: AACheckout.BundleIncludedSsr[],
    paxTitle: AACheckout.PaxTitle,
  ) {
    const convertedSrrs: AACheckout.BundleIncludedSsr[] = ssrs.map((ssr) => {
      return {
        ssrCode: ssr.ssrCode,
        ssrName: ssr.ssrName,
        ancillaryType: ssr.ancillaryType,
        mandatory: ssr.mandatory,
        journeyDetails: ssr.journeyDetails.map((j) => ({
          designator: j.designator,
          selectedSsrs: [],
          limitPerPassenger: j.limitPerPassenger,
        })),
      };
    }) as any;

    const handledTypes: SsrType[] = [
      AACheckoutConst.BundleAncillaryTypeEnum.MEALS,
      AACheckoutConst.BundleAncillaryTypeEnum.SEAT,
      AACheckoutConst.BundleAncillaryTypeEnum.BAGGAGE,
    ];

    const processedSsrs = handledTypes.flatMap((type) =>
      this.generateSsrsByType(
        type,
        convertedSrrs.filter((s) => s.ancillaryType === type),
        paxTitle,
      ),
    );

    const otherSsrs = convertedSrrs.filter(
      (s) => !handledTypes.includes(s.ancillaryType as SsrType),
    );

    return [...processedSsrs, ...otherSsrs];
  }

  generateByJourney(bundleFlight: AACheckout.BundleFlight) {
    if (!bundleFlight || !bundleFlight.bundleCode) {
      return {
        plainAddonItemsForCheckout: [],
        plainAddonItems: [],
      };
    }

    const plainAddonItemsForCheckout: AACheckout.PlainAddonItem[] = [];
    const plainAddonItems: AACheckout.PlainAddonItem[] = [];
    const allIncludedSsrs: AACheckout.BundleIncludedSsr[] = [];

    for (const paxTitle of this.paxTitles) {
      const { includedSsrs } = bundleFlight;
      const finalSsrs = this.generateSsrs(includedSsrs, paxTitle);

      plainAddonItemsForCheckout.push({
        id: bundleFlight.bundleCode,
        passengerReferenceId: paxTitle.paxId,
        category: 'Bundle',
        groupBy: 'Bundle',
        currency: bundleFlight.price.currency,
        name: bundleFlight.bundleName,
        price: bundleFlight.price.price,
        type: 'bundle',
        departureStation: bundleFlight.designator.departureStation,
        arrivalStation: bundleFlight.designator.arrivalStation,
        properties: {
          bundleCode: bundleFlight.bundleCode,
          bundleName: bundleFlight.bundleName,
          includedSsrs: finalSsrs,
        },
      });

      allIncludedSsrs.push(...finalSsrs);
    }

    plainAddonItems.push({
      id: bundleFlight.bundleCode,
      passengerReferenceId: '',
      category: 'Bundle',
      groupBy: 'Bundle',
      currency: bundleFlight.price.currency,
      name: `${bundleFlight.bundleName} 适用${this.paxTitles.length}名乘客`,
      price: bundleFlight.price.price,
      type: 'bundle',
      departureStation: bundleFlight.designator.departureStation,
      arrivalStation: bundleFlight.designator.arrivalStation,
      properties: {
        bundleCode: bundleFlight.bundleCode,
        bundleName: bundleFlight.bundleName,
        includedSsrs: allIncludedSsrs,
      },
    });

    return { plainAddonItemsForCheckout, plainAddonItems };
  }

  generate() {
    const {
      plainAddonItemsForCheckout: departPlainAddonItemsForCheckout,
      plainAddonItems: departPlainAddonItems,
    } = this.generateByJourney(this.selectedBundleData?.departFlight);
    const {
      plainAddonItemsForCheckout: returnPlainAddonItemsForCheckout,
      plainAddonItems: returnPlainAddonItems,
    } = this.generateByJourney(this.selectedBundleData?.returnFlight);

    console.log('***meals and seat validationResult***', this.validationResult);

    return {
      plainAddonItemsForCheckout: departPlainAddonItemsForCheckout.concat(
        returnPlainAddonItemsForCheckout,
      ),
      plainAddonItems: departPlainAddonItems.concat(returnPlainAddonItems),
    };
  }
}
