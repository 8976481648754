import { CURRENCY_DEFAULT } from '@/helper/constants';

type BaggageCode = 'cabin_bag' | 'hold_bag' | 'sports_equip';

// find original baggage data
function findOriginalBaggageData(
  baggageResponse: AACheckout.BaggageResponse,
  baggageItem,
) {
  for (const journey of baggageResponse.ancillary.baggage.data) {
    // try to find from all baggage types
    for (const baggageType of Object.keys(journey.ancillarylist)) {
      const foundItem = journey.ancillarylist[baggageType].find(
        (item) => item.id === baggageItem.id,
      );
      if (foundItem) return foundItem;
    }
  }
  return null;
}

function removeMiddleSpaces(str: string): string {
  return str.replace(/\s+/g, '');
}

// build plain addon item
function constructPlainAddonItem(
  originalBaggageItem,
  baggageItem,
  designator,
  paxId,
): AACheckout.PlainAddonItem {
  const typeName = getBaggageTypeName(
    originalBaggageItem.properties.baggageDimension[0].baggageCode,
  );
  const weight = removeMiddleSpaces(baggageItem.title[0].weight);
  return {
    id: originalBaggageItem.id,
    groupBy: originalBaggageItem.id,
    currency: CURRENCY_DEFAULT,
    name: `${typeName} ${weight}`,
    price: baggageItem.amount,
    type: 'baggage',
    category: 'Baggage',
    departureStation: designator.departureStation,
    arrivalStation: designator.arrivalStation,
    properties: originalBaggageItem.properties,
    passengerReferenceId: paxId,
    fastpassBaggage: baggageItem.isFastpassBaggage,
    isBundled: baggageItem.withBundle,
  };
}

function getBaggageTypeName(baggageCode: BaggageCode): string {
  switch (baggageCode) {
    case 'cabin_bag':
      return '手提包';
    case 'hold_bag':
      return '托运行李';
    case 'sports_equip':
      return '运动装备';
    default:
      return '';
  }
}

export function mapSelectionToPlainAddonItems(
  userSelection: {
    depart: AACheckout.FlightBaggageSelection[];
    return?: AACheckout.FlightBaggageSelection[];
  },
  baggageResponse: AACheckout.BaggageResponse,
  departDesignator,
  returnDesignator,
  paxTitles: AACheckout.PaxTitle[],
): AACheckout.PlainAddonItem[] {
  const plainAddonItems = [];

  userSelection.depart.forEach((selection, index) => {
    processFlightSelection(
      selection,
      baggageResponse,
      departDesignator, // use departDesignator for depart flight
      plainAddonItems,
      paxTitles[index]?.paxId,
    );
  });

  if (userSelection.return) {
    userSelection.return.forEach((selection, index) => {
      processFlightSelection(
        selection,
        baggageResponse,
        returnDesignator, // use returnDesignator for return flight
        plainAddonItems,
        paxTitles[index]?.paxId,
      );
    });
  }
  return plainAddonItems;
}

// handle baggage selection for single flight
function processFlightSelection(
  flightSelection: AACheckout.FlightBaggageSelection,
  baggageResponse: AACheckout.BaggageResponse,
  designator,
  plainAddonItems,
  paxId,
) {
  Object.entries(flightSelection).forEach(([baggageType, baggageItems]) => {
    baggageItems.forEach((baggageItem) => {
      const originalBaggageItem = findOriginalBaggageData(
        baggageResponse,
        baggageItem,
      );

      if (originalBaggageItem) {
        plainAddonItems.push(
          constructPlainAddonItem(
            originalBaggageItem,
            baggageItem,
            designator,
            paxId,
          ),
        );
      }
    });
  });
}

// calculate total price for single flight
export function calculateFlightBaggagePrice(
  flightSelection: AACheckout.FlightBaggageSelection,
): number {
  let totalPrice = 0; // initial total price as 0

  // iterate over each baggage type in flightSelection
  Object.values(flightSelection).forEach((baggageSelections) => {
    // calculate the total price for each baggage type
    baggageSelections.forEach((baggage) => {
      baggage && (totalPrice += baggage.amount);
    });
  });

  return totalPrice;
}

// get mapped baggage selection from user's selection
export function processUserBaggageSelection(
  userSelection,
  paxBaggageData,
  returnJourneyPaxBaggageData = [],
) {
  const mappedBaggageSelection = { depart: [], return: [] };

  // handle baggage selection for depart flight
  if (userSelection.depart && userSelection.depart.length > 0) {
    userSelection.depart.forEach((selection, index) => {
      const flightSelection = mapSelectionToBaggageOptions(
        selection,
        paxBaggageData[index],
      );
      mappedBaggageSelection.depart.push(flightSelection);
    });
  }

  // handle baggage selection for return flight
  if (userSelection.return && userSelection.return.length > 0) {
    userSelection.return.forEach((selection, index) => {
      const flightSelection = mapSelectionToBaggageOptions(
        selection,
        returnJourneyPaxBaggageData[index] || [], // use return journey's baggage data
      );
      mappedBaggageSelection.return.push(flightSelection);
    });
  }

  return mappedBaggageSelection;
}

// map user's selection to baggage options
function mapSelectionToBaggageOptions(selection, baggageOptions) {
  const flightSelection = {};

  // iterate over each baggage type
  Object.keys(selection).forEach((baggageType) => {
    const selectedIndices = selection[baggageType];
    const options =
      baggageOptions.find((option) => option.baggageType === baggageType)
        ?.baggageList || [];

    // get the corresponding baggage options based on the selected indices
    flightSelection[baggageType] = selectedIndices
      .map((index) => options[index])
      .filter((option) => option !== undefined);
  });

  return flightSelection;
}

export function initialSelectedBaggageData(baggageData, includeBundle = true) {
  const userSelections = {
    depart: [],
    return: [],
  };

  // initialize depart baggage selection
  baggageData.paxBaggageData.forEach((paxBaggage) => {
    const paxSelection = {
      handCarry: [],
      checkedBaggage: [],
      sportsEquip: [],
    };

    paxBaggage.forEach((baggageTypeData) => {
      baggageTypeData.baggageList.forEach((item, index) => {
        if ((includeBundle && item.withBundle) || item.isIncluded) {
          paxSelection[baggageTypeData.baggageType].push(index);
        }
      });
    });

    userSelections.depart.push(paxSelection);
  });

  // initialize return baggage selection (if any)
  baggageData.returnJourneyPaxBaggageData.forEach((paxBaggage) => {
    const paxSelection = {
      handCarry: [],
      checkedBaggage: [],
      sportsEquip: [],
    };

    paxBaggage.forEach((baggageTypeData) => {
      baggageTypeData.baggageList.forEach((item, index) => {
        if (
          (includeBundle && item.withBundle) ||
          item.isFastpassBaggage ||
          item.isIncluded
        ) {
          paxSelection[baggageTypeData.baggageType].push(index);
        }
      });
    });

    userSelections.return.push(paxSelection);
  });

  return userSelections;
}
